<template>
	<div>
		<a-modal
			:title="renameModal.dup ? 'Enter name of the duplicate' : renameModal.move ? 'Move template' : 'Rename template'"
			:okText="renameModal.dup ? 'Duplicate' : renameModal.move ? 'Move' : 'Rename'" :visible="renameModal.show"
			@ok="renameContract" @cancel="() => { renameModal.show = false; renameModal.move = false; }">
			<template v-if="renameModal.move">
				<a-form-model-item label="Contract Type" prop="type">
					<a-select v-model="renameModal.name" class="w-full">
						<a-select-option :value="t.value" v-for="t in contractTypes" :key="t.value">{{ t.label
							}}</a-select-option>
					</a-select>
				</a-form-model-item>
			</template>
			<a-input v-else v-model="renameModal.name" />
		</a-modal>

		<a-modal v-model="exportModal.visible" width="500px" :footer="null" @cancel="closeExportModal" :centered="true">
			<BHLoading :show="loading" />
			<div class="mb-2">
				<h5 class="mb-4" v-text="'Export to Existing Project'" />

				<p class="mb-2" style="color: #40454c; font-size: 14px">
					Select a Project<span style="color: var(--danger)"> *</span>
				</p>
				<a-select size="large" v-model="exportModal.instance" class="w-full" placeholder="Select a Project"
					show-search :filter-option="filterOption">
					<a-select-option v-for="(inst, instI) in instances" :value="inst.id" :key="inst + instI">{{
						inst.name
					}}</a-select-option>
				</a-select>
			</div>
			<div class="dF jE mt-5">
				<a-button class="cancel-button" @click="closeExportModal">CANCEL</a-button>
				<a-button class="ml-3" type="primary" @click="confirmExport()">
					EXPORT
				</a-button>
			</div>
		</a-modal>

		<div class="dF jSB">
			<h4 class="pb-2">{{ title }}</h4>
			<MoreActions v-if="selectedRowKeys.length" shape="button" buttonText="Bulk Actions" :items="bulkActions"
				@action="bulkActioned" />
		</div>

		<a-table :rowKey="e => e.id" :row-selection="rowSelection" :columns="columns" :data-source="contracts"
			class="white-table">
			<template slot="description" slot-scope="record">
				<span v-if="record.description"></span>
				<span v-else class="text-med-gray">
					No Description...
				</span>

			</template>
			<template slot="name" slot-scope="record">
				<router-link :to="`/template/${record.id}/prepare`" class="fw-500">
					<Icons type="contract-file" style="width:10px;color:var(--red);" class="mr-2" />
					{{ record.name }}
				</router-link>
			</template>
			<template slot="pagesLength" slot-scope="record">
				{{ record?.pages?.length }}
			</template>
			<template slot="updatedAt" slot-scope="record">
				{{ $formatDate(record.updatedAt) }}
			</template>
			<template slot="type" slot-scope="record">
				{{ lookup[record.type] ? lookup[record.type] : record.type[0].toUpperCase() + record.type.substr(1) }}
			</template>
			<template slot="action" slot-scope="record">
				<MoreActions :items="getMoreActions(record)" :val="record" @action="action" />
			</template>
		</a-table>
	</div>
</template>

<script>
import Icons from '@/components/common/BHIcons'
import MoreActions from 'bh-mod/components/common/MoreActions'
import BHLoading from 'bh-mod/components/common/Loading'

export default {
	props: {
		contracts: {
			type: Array,
			default: () => []
		},
		title: {
			type: String,
			default: 'Contract Templates'
		},
	},

	components: { Icons, MoreActions, BHLoading },
	data() {
		return {
			renameModal: {
				dup: false,
				move: false,
				show: false,
				id: '',
				name: ''
			},
			exportModal: {
				visible: false,
				template: {},
				instance: ''
			},
			lookup: {
				lease: 'Main Lease Agreement',
				purchase: 'Main Purchase Agreement',
				amendPurchase: 'Amendment/Document Agreement',
				amendLease: 'Amendment Lease Agreement',
				reservation: 'Reservation Agreement',
				other: 'Supporting Document Agreement'
			},
			types: [
				{
					label: 'Main Purchase Template',
					value: 'purchase'
				},
				{
					label: 'Main Lease Template',
					value: 'lease'
				},
				{
					label: 'Amendment/Document Template',
					value: 'amendPurchase'
				},
				{
					label: 'Amendment Lease Template',
					value: 'amendLease'
				},
				{
					label: 'Reservation Agreement Template',
					value: 'reservation'
				},
				{
					label: 'Supporting Document Template',
					value: 'other'
				},
			],
			loading: false,
			selectedRowKeys: [],
			moreActions: [
				{
					label: 'Preview',
					value: 'preview',
					icon: 'fe-eye',
				},
				{
					label: 'Rename',
					value: 'rename',
					icon: 'fa-edit',
				},
				{
					label: 'Edit',
					value: 'edit',
					icon: 'fa-pencil',
				},
				{
					label: 'Duplicate',
					value: 'duplicate',
					icon: 'fa-copy',
				},
				{
					label: 'Move',
					value: 'move',
					icon: 'fe-log-out',
				},
				{
					label: 'Export to Project',
					value: 'export',
					icon: 'fe-upload',
				},
				{
					label: 'Delete',
					value: 'delete',
					icon: 'fe-trash'
				},
			],
			columns: [
				{
					title: 'Contract Name',
					key: 'name',
					scopedSlots: { customRender: 'name' },
					sorter: (a, b) => a.name.localeCompare(b.name),
				},
				{
					title: 'Pages',
					key: 'pagesLength',
					scopedSlots: { customRender: 'pagesLength' },
					sorter: (a, b) => a.pages.length - b.pages.length,
				},
				{
					title: 'Contract Type',
					key: 'cType',
					scopedSlots: { customRender: 'type' },
					sorter: (a, b) => a.type.localeCompare(b.type),
				},
				{
					title: 'Last Modified',
					key: 'updatedAt',
					scopedSlots: { customRender: 'updatedAt' },
					sorter: (a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime(),
					defaultSortOrder: 'descend'
				},
				{
					title: 'Action',
					key: 'action',
					width: '10%',
					scopedSlots: { customRender: 'action' },
				},
			]
		}
	},
	computed: {
		instances() {
			return this.$store.state.instances.filter(x => x.id != this.instance.id).sort((a, b) => a.name.localeCompare(b.name))
		},
		instance() {
			return this.$store.state.instance
		},
		rowSelection() {
			const { selectedRowKeys } = this;
			return {
				selectedRowKeys,
				onChange: this.onSelectChange,
				hideDefaultSelections: true,
				onSelection: this.onSelection,

				getCheckboxProps: record => ({
					props: {
						disabled: record.name === 'Disabled User', // Column configuration not to be checked
						name: record.name,
					},
				}),
			}
		},

		contractTypes() {
			let types = this.types;
			if (this.reservationAgreementExist || !this.$store.getters.isPremiumPackage) {
				types = types.filter(t => t.value !== 'reservation')
			}
			return types
		},

		reservationAgreementExist() {
			const contracts = Object.values(this.$store.state.appData.templates);

			return !!(contracts.find(c => c.type === 'reservation'))
		},

        filter() {
            return this.$store.state.appData.filter
        },

        bulkActions() {
            const actions = [
                {
                    label: 'Bulk Delete',
                    value: 'delete'
                }
            ];

            if (this.filter.value !== 'archived') {
                actions.push({
                    label: 'Bulk Archive',
                    value: 'archive'
                });
            } else {
                actions.push({
                    label: 'Bulk Unarchive',
                    value: 'unarchive'
                });
            }

            return actions;
        },
	},
    watch: {
        'renameModal.show'(val) {
            if (!val) {
                this.renameModal.dup = false
                this.renameModal.move = false
                this.renameModal.id = ''
                this.renameModal.name = ''
            }
        },

        title() {
            this.selectedRowKeys = [];
        }
    },
	methods: {
		closeExportModal() {
			this.exportModal = {
				visible: false,
				template: {},
				instance: ''
			}
		},
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
		},
		async confirmExport() {
			if (this.exportModal.instance == '') {
				return this.$message.error('Please select an instance')
			}
			this.loading = true
			this.$api.post(`/contract-templates/:instance/${this.exportModal.template.id}/copytemplate?instance=${this.exportModal.instance}`, { name: this.exportModal.template.name + ' COPY' }).then(({ data }) => {
				this.$message.success(`Exported Contract Template: ${this.exportModal.template.name}`)
				this.closeExportModal();
			}).catch((err) => {
				this.$toastError(err, `Error occurred while exporting the contract template. Please try again!`)
			}).finally(() => {
				this.loading = false
				this.closeExportModal()
			})
		},
		renameContract() {
			if (!this.renameModal.name.trim()) {
				return this.$message.error('Please enter a name')
			}
			this.$store.commit('LOAD', true)
			if (this.renameModal.dup) {
				this.$api.post(`/contract-templates/:instance/${this.renameModal.id}/copytemplate`, { name: this.renameModal.name }).then(({ data }) => {
					this.$store.commit('SET_PROP', { where: ['templates', data.id], what: data })
				}).catch((err) => {
					this.$toastError(err, `Error occurred while coping the contract template. Please try again!`)
				}).finally(() => {
					this.$store.commit('LOAD', false)
					this.renameModal.show = false
				})
			} else if (this.renameModal.move) {
				let sendObj = JSON.parse(JSON.stringify(this.contracts.find(x => x.id == this.renameModal.id)))
				sendObj.type = this.renameModal.name
				this.$api.put(`/contract-templates/:instance/${this.renameModal.id}`, sendObj).then(({ data }) => {
					this.$store.commit('SET_PROP', { where: ['templates', data.id], what: data })
				}).catch((err) => {
					this.$toastError(err, `Error occurred while moving the contract template. Please try again!`)
				}).finally(() => {
					this.$store.commit('LOAD', false)
					this.renameModal.show = false
				})
			} else {
				this.$api.put(`/contract-templates/:instance/${this.renameModal.id}`, { name: this.renameModal.name }).then(({ data }) => {
					this.$store.commit('SET_PROP', { where: ['templates', data.id], what: data })
				}).catch((err) => {
					this.$toastError(err, `Error occurred while renaming the contract template. Please try again!`)
				}).finally(() => {
					this.$store.commit('LOAD', false)
					this.renameModal.show = false
				})
			}
		},
        async bulkActioned({ key }) {
            if (key === 'delete') {
                if (this.$p < 40) return this.$message.error('You do not have permission to delete contracts');
                this.$confirm({
                    title: 'Bulk Delete',
                    content: (h) => <div>Are you sure you want to delete the selected Contract Templates ({this.selectedRowKeys.length} in Total) ?</div>,
                    okText: 'YES',
                    okType: 'danger',
                    cancelText: 'NO',
                    confirmLoading: this.loading,
                    onOk: async () => {
                        try {
                            this.loading = true;
                            await this.$api.put(`/contract-templates/${this.$store.state.instance.id}/delete`, this.selectedRowKeys);
                            this.selectedRowKeys.forEach(id => {
                                this.$store.commit('SET_PROP', { where: ['templates', id], del: true });
                            });
                            this.selectedRowKeys = [];
                            this.$message.success('Selected Contract Templates deleted successfully');
                        } catch (err) {
                            this.$toastError(err, `Error occurred while deleting the selected contracts. Please try again!`);
                        } finally {
                            this.loading = false;
                        }
                    }
                });
            } else if (['unarchive', 'archive'].includes(key)) {
                if (this.$p < 40) return this.$message.error('You do not have permission to update contracts');
                const action = key === 'archive' ? 'archive' : 'unarchive';
                this.$confirm({
                    title: 'Bulk ' + action.charAt(0).toUpperCase() + action.slice(1),
                    content: (h) => <div>Are you sure you want to <strong>{action}</strong> the selected Contract Templates <strong>({this.selectedRowKeys.length} in Total)</strong>?</div>,
                    okText: 'YES',
                    okType: 'danger',
                    cancelText: 'NO',
                    confirmLoading: this.loading,
                    onOk: async () => {
                        try {
                            this.loading = true;
                            await this.$api.put(`/contract-templates/${this.$store.state.instance.id}/archiveUpdate`, {
                                ids: this.selectedRowKeys,
                                archived: key === 'archive'
                            });
                            this.selectedRowKeys.forEach(id => {
                                this.$store.commit('SET_PROP', { where: ['templates', id], what: { archived: key === 'archive' } });
                            });
                            this.selectedRowKeys = [];
							this.$message.success(`Selected Contract Template ${action}d successfully`);
                        } catch (err) {
                            this.$toastError(err, `Error occurred while updating the selected contracts. Please try again!`);
                        } finally {
                            this.loading = false;
                        }
                    }
                });
            }
        },
		async action(e) {
			if (['rename', 'move', 'duplicate'].includes(e.key)) {
				this.renameModal.show = true;
				this.renameModal.dup = e.key === 'duplicate';
				this.renameModal.move = e.key === 'move';
				this.renameModal.id = e.val.id;
				this.renameModal.name = e.key === 'move' ? this.contracts.find(x => x.id === e.val.id).type : e.key === 'duplicate' ? e.val.name + ' (Copy)' : e.val.name;
			} else if (e.key === 'preview') {
				this.$store.commit('LOAD')
				try {
					let { data } = await this.$api.post(`/contract-templates/:instance/${e.val.id}/preview`)
					let { data: url } = await this.$api.get(`/contract-templates/:instance/getfile?key=${data}`);

					this.$store.commit('SHOW_PREVIEW', url)
				} catch (err) {
					this.$toastError(err, `Error occurred while previewing contract. Please try again!`)
				} finally {
					this.$store.commit('LOAD', false)
				}
			} else if (e.key === 'edit') {
				this.$router.push(`/template/${e.val.id}/prepare`)
			} else if (e.key === 'export') {
				this.exportModal = {
					visible: true,
					template: e.val,
					instance: ''
				}
			} else if (e.key === 'delete') {
				if (this.$p < 40) return this.$message.error('You do not have permission to delete contracts')
				this.$confirm({
					title: 'Delete',
					content: (h) => <div>Are you sure you want to delete <strong>{e.val.name}</strong> Contract Template?</div>,
					okText: 'YES',
					okType: 'danger',
					cancelText: 'NO',
                    confirmLoading: this.loading,
					onOk: async () => {
						try {
							this.loading = true;
							await this.$api.delete(`/contract-templates/${this.$store.state.instance.id}/${e.val.id}`);
							this.$store.commit('SET_PROP', { where: ['templates', e.val.id], del: true });
							this.$message.success(`Contract Template deleted successfully`);
						} catch (err) {
							this.$toastError(err, `Error occurred while deleting contract. Please try again!`);
						} finally {
							this.loading = false;
						}
					}
				})
			} else if (e.key === 'archive' || e.key === 'unarchive') {
				const action = e.key;
				const confirmText = action.toUpperCase();
				const newArchivedState = e.key === 'archive';
				if (this.$p < 40) return this.$message.error(`You do not have permission to ${action} contracts`);
				this.$confirm({
					title: action.charAt(0).toUpperCase() + action.slice(1),
					content: (h) => <div>Are you sure you want to {action} <strong>{e.val.name}</strong> Contract Template?</div>,
					okText: confirmText,
					okType: 'danger',
					cancelText: 'NO',
					confirmLoading: this.loading,
					onOk: async () => {
						try {
							this.loading = true;
							const { data } = await this.$api.put(`/contract-templates/${this.$store.state.instance.id}/${e.val.id}`, { archived: newArchivedState });
							this.$store.commit('SET_PROP', { where: ['templates', e.val.id], what: { ...data } });
							this.$message.success(`Contract Template ${action}d successfully`);
						} catch (err) {
							this.$toastError(err, `Error occurred while ${action}ing contract. Please try again!`);
						} finally {
							this.loading = false;
						}
					}
				})
			}
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys;
		},

		getMoreActions(record) {
			return [
				...this.moreActions,
				...([record.archived ? {
					label: 'Unarchive',
					value: 'unarchive',
					icon: 'fe-archive',
				} : {
					label: 'Archive',
					value: 'archive',
					icon: 'fe-archive'
				}])
			]
		},

		async fetchInstances() {
			try {
				const { data } = await this.$api.get('/instances');
				const filteredData = data
					.filter(x => x.role?.name === 'bh_admin' && x.instance.productType === this.instance.productType)
					.map(x => ({
						id: x.instance.id,
						name: x.instance.name
					}));
				this.$store.commit('SET_INSTANCES', filteredData);
			} catch (err) {
				this.$toastError(err, 'Error occurred while fetching instances. Please try again!');
			}
		}
	},
	mounted() {
		this.fetchInstances()
	}
}
</script>

<style></style>
